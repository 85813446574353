.App {
  text-align: center;
}

.App-logo {
  max-width: 6.5em;
  max-height: 2.5em;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 0.5em;
  padding: 0.25em;
  display: inline-block;
  font-size: inherit;
  pointer-events: none;
}

/*
 * Context menu styling
 */

.react-contextmenu {
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 12px;
  color: #373a3c;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  outline: none;
  opacity: 0;
  pointer-events: none;
  z-index: 1000;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
}

.react-contextmenu-item {
  /*width: 200px;*/
  padding: 3px 20px;
  font-weight: 400;
  line-height: 1.5;
  color: #373a3c;
  text-align: inherit;
  white-space: nowrap;
  background: 0 0;
  border: 0;
  cursor: pointer;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item:hover {
  color: #fff;
  background-color: #0275d8;
  border-color: #0275d8;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  color: #878a8c;
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item--divider {
  margin-bottom: 3px;
  padding: 2px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
}

.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu
  > .react-contextmenu-item:after {
  content: "▷";
  display: inline-block;
  position: absolute;
  right: 7px;
}

/*
 * Fix reflex splitter
 */
body .reflex-container > .reflex-splitter {
  z-index: 0;
}

/*
 * Configurable styling for components
 */

.tree-node-active {
  background-color: rgb(128, 190, 230);
  color: #fff;
}

.tree-node-inactive {
  color: #000;
}

.tree-node-branch {
  color: #e2c089;
}

.tree-node-leaf {
  color: #aaa;
}

.tree-node-toggle {
  color: #555;
}

.tree-node-spinner {
  color: #555;
}

/*
 * Configure objectcard layout
 */
.row .card-row {
  flex: 0 1 auto;
  width: 100%;
  /* flex: 0 1 100%; */
}

/* .card-body{
  flex: none
} */
/*
 * Configure filler class
 */
.npt-filler {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  overflow: auto;
}

/*
 * Configure tabs to fill height via css
 */
.tab-content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
  /* overflow-x: auto; */
  overflow-x: hidden;
  margin-bottom: 0.5rem;
}

.tab-content .card-tab-content {
  position: relative;
  width: 100%;
  height: 100%;
}

.tab-content:last-child {
  margin-bottom: 0rem;
}

.grid-row {
  flex: 0 1 auto;
}

.card-form {
  height: 100%;
  overflow-y: auto;
  padding: 0px 5px;
}

.modal .tab-content {
  overflow-y: unset;
  overflow-x: unset;
}

body .tab-content > .active {
  display: flex;
  flex-direction: column;
}

/*
 * Configure modals backdrop overflow
 */
.modal,
.modal-backdrop {
  z-index: 1050 !important;
}

.nav.nav-tabs > .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.menuItemUser > .nav-item > .nav-link span,
.menuItemUser > .nav-item > .nav-link svg {
  color: white;
}

.npt-main-menu > div a,
.npt-main-menu > div a:hover,
.npt-main-menu > div a:focus {
  color: white !important;
}

.npt-main-menu-root-item:hover:after,
.npt-active-root-dropdown:after,
.over-drop-item.show > .npt-main-menu-root-item:after {
  content: "";
  position: absolute;
  height: 4px;
  background: white;
  width: 100%;
  bottom: 0;
  left: 0;
}

/* .npt-active-root-dropdown:after,.npt-main-menu>div:hover:after{
  content:'';
  position:absolute;
  height:4px;
  background:white;
  width:100%;
  bottom: 0;
 } */

.npt-table-header-column {
  height: 100%;
  /* position: relative;   */
  border-right: 1px solid #ccc;
}

.npt-table-header-column:last-child {
  border-right: none;
}

.npt-actions-ctxsubmenu {
  overflow-y: auto;
  max-height: 10rem;
}

.cb-select__control--is-disabled {
  background-color: #e9ecef;
  background-color: #e9ecef !important;
}

.npt-user-menu > .over-drop-item:hover > .over-drop-item-title,
.npt-user-menu > .over-drop-item.show > .over-drop-item-title {
  background: transparent !important ;
}

.npt-main-menu-root-item {
  padding: 1rem 0.5rem !important;
  text-decoration: unset !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border-color: var(--primary) var(--primary) white !important;
}

.card-slider ul.slick-dots {
  top: -10px !important;
  height: fit-content;
}

.card-slider .slick-active .custom-slick-dot {
  background: var(--primary);
  color: white !important;
}
